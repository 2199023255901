import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ServiceData } from '@app/modules/service-data/service-data.types';
import { ServiceDataUtilsService } from '@app/modules/service-data/services/service-data-utils.service';
import { ServiceDataService } from '@app/modules/service-data/services/service-data.service';
import { SetServiceDataAction } from '@app/modules/service-data/store/service-data.actions';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
  NbInputModule,
  NbToastrService,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { inputAtLeastOneValidator } from '@shared/validators/input-at-least-one-validator';
import { map, switchMap } from 'rxjs';

@Component({
  selector: 'app-service-data-search',
  standalone: true,
  imports: [
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './service-data-search.component.html',
})
export class ServiceDataSearchComponent {
  protected searchForm = new FormGroup(
    {
      id: new FormControl<string>(''),
      kitId: new FormControl<string>(''),
      patientName: new FormControl<string>(''),
      patientEmail: new FormControl<string>(''),
      paymentNotes: new FormControl<string>(''),
    },
    [inputAtLeastOneValidator()]
  );

  protected loading = false;

  constructor(
    private dialogRef: NbDialogRef<ServiceDataSearchComponent>,
    private store: Store,
    private toastr: NbToastrService,
    private serviceDataService: ServiceDataService,
    private serviceDataUtilsService: ServiceDataUtilsService
  ) {}

  protected search() {
    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;

    this.serviceDataService
      .getServiceDataList(
        {
          ids: [this.searchForm.get('id').value],
          kitIds: [this.searchForm.get('kitId').value],
          patientNameToken: this.searchForm.get('patientName').value,
          patientEmailToken: this.searchForm.get('patientEmail').value,
          paymentNotesToken: this.searchForm.get('paymentNotes').value,
        },
        {}
      )
      .pipe(
        switchMap((result) =>
          this.store
            .dispatch(new SetServiceDataAction(result.items))
            .pipe(map(() => result))
        )
      )
      .subscribe({
        next: (result) => {
          const serviceDataItems = result.items;
          if (serviceDataItems.length === 1) {
            this.serviceDataUtilsService.openDetails(serviceDataItems[0].id);
            this.dismiss();
          } else if (
            serviceDataItems.length > 1 &&
            serviceDataItems.length < 100
          ) {
            this.serviceDataUtilsService.openResults(
              serviceDataItems.map((serviceData: ServiceData) => serviceData.id)
            );
            this.dismiss();
          } else if (serviceDataItems.length >= 100) {
            this.toastr.danger(
              `Too many results returned, narrow your search!`,
              'Too many results!',
              { duration: 5000 }
            );
          } else {
            this.toastr.danger(`Query returned no results!`, 'Not found!', {
              duration: 5000,
            });
          }
          this.loading = false;
        },
        error: () => {
          this.toastr.danger(`Query returned no results!`, 'Not found!', {
            duration: 5000,
          });
          this.loading = false;
        },
      });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
