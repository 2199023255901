<nb-card style="min-width: 650px">
  <nb-card-header class="position-relative">
    <p class="mb-0">{{ 'serviceData.search.title' | translate }}</p>
    <nb-icon icon="close" (click)="dismiss()"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <form [formGroup]="searchForm" class="col-12">
        <div class="row align-items-center mb-3">
          <label class="col-3 label">{{
            'serviceData.search.form.id' | translate
          }}</label>
          <div class="col-9">
            <input
              nbInput
              fullWidth
              type="text"
              formControlName="id"
              [placeholder]="
                'serviceData.search.form.placeholders.id' | translate
              "
            />
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-3 label">
            {{ 'serviceData.search.form.kitId' | translate }}
          </div>
          <div class="col-9">
            <input
              nbInput
              fullWidth
              type="text"
              formControlName="kitId"
              [placeholder]="
                'serviceData.search.form.placeholders.barcode' | translate
              "
            />
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-3 label">
            {{ 'serviceData.search.form.patientName' | translate }}
          </div>
          <div class="col-9">
            <input
              nbInput
              fullWidth
              type="text"
              formControlName="patientName"
              [placeholder]="
                'serviceData.search.form.placeholders.patientName' | translate
              "
            />
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-3 label">
            {{ 'serviceData.search.form.patientEmail' | translate }}
          </div>
          <div class="col-9">
            <input
              nbInput
              fullWidth
              type="text"
              formControlName="patientEmail"
              [placeholder]="
                'serviceData.search.form.placeholders.patientEmail' | translate
              "
            />
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-3 label">
            {{ 'serviceData.search.form.payerName' | translate }}
          </div>
          <div class="col-9">
            <input
              nbInput
              fullWidth
              type="text"
              formControlName="paymentNotes"
              [placeholder]="
                'serviceData.search.form.placeholders.payerName' | translate
              "
            />
          </div>
        </div>
        @if (searchForm.dirty && searchForm.errors?.atLeastOneRequired) {
          <div class="row">
            <div class="offset-3 col-9">
              <span class="caption status-danger">
                {{ 'serviceData.search.form.validator' | translate }}
              </span>
            </div>
          </div>
        }
      </form>
      <div class="col-12 d-flex flex-row-reverse mt-3">
        <button
          nbButton
          status="primary"
          [disabled]="searchForm.invalid || loading"
          (click)="search()"
        >
          {{ 'serviceData.search.form.button' | translate }}
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
